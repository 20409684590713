export const languageStorageKey = 'lng_i18n';

export const getBrowserLocale = () => {
  const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;

  if (!navigatorLocale) {
    return undefined;
  }

  const enGroupRegex = /^en(-|_)/;
  const trimmedLocale = enGroupRegex.test(navigatorLocale) ? 'en' : navigatorLocale.trim();

  return trimmedLocale;
};

export const getInitialLocale = () => {
  return localStorage.getItem(languageStorageKey) ?? getBrowserLocale() ?? 'en';
};

export const setLocale = (key: string) => {
  return localStorage.setItem(languageStorageKey, key);
};
