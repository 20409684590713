import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'ant-design-vue/dist/antd.css';
import VueI18n from 'vue-i18n';
import en from '@/i18n/en.json';
import ptBr from '@/i18n/pt-BR.json';
import es from '@/i18n/es.json';

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import { Button, Card, Spin, Input, Form, Select } from 'ant-design-vue';
import { getInitialLocale } from './services/locale';

Vue.config.productionTip = false;

Vue.use(Button);
Vue.use(Card);
Vue.use(Spin);
Vue.use(Input);
Vue.use(Form);
Vue.use(VueI18n);
Vue.use(Select);

const injectUserSnap = () => {
  (window as any).onUsersnapCXLoad = function (api) {
    api.init({ button: null });
    (window as any).Usersnap = api;
  };
  const script = document.createElement('script') as any;
  script.defer = 1;
  script.src = 'https://widget.usersnap.com/load/e1d754ae-d2ae-49f8-8352-a1b2b7310c96?onload=onUsersnapCXLoad';
  document.getElementsByTagName('head')[0].appendChild(script);
};
injectUserSnap();

const i18n = new VueI18n({
  locale: getInitialLocale(),
  fallbackLocale: 'en',
  messages: {
    en,
    'pt-BR': ptBr,
    es: es
  }
});

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    ignoreErrors: ['Request failed with status code 403', 'Request failed with status code 404'],
    logErrors: true,
    environment: process.env.VUE_APP_ENVIRONMENT,
    debug: process.env.VUE_APP_ENVIRONMENT !== 'prod',
    release: process.env.VUE_APP_RELEASE,
    integrations: [
      new BrowserTracing({
        // TODO: we don't track backend APIs now as it's creating too many OPTIONS/Preflight request
        tracingOrigins: ['dont-track-anything']
      })
    ],
    trackComponents: true,
    tracesSampleRate: 0.1
  });
}

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
