import Axios, { AxiosResponse } from 'axios';
import { LoginInfo } from '@/interfaces/loginInfo';
import { User, CreateUserResponse } from '@/interfaces/user';

class API {
  static _PRODUCT_MANAGER_URL =
    (window.location.host === process.env.VUE_APP_MAHINDRA_AUTH_HOST
      ? process.env.VUE_APP_MAHINDRA_PRODUCT_MANAGER
      : process.env.VUE_APP_PRODUCT_MANAGER) + '/api';

  static transport = Axios.create({
    withCredentials: true
  });

  static authorize(): Promise<AxiosResponse<any>> {
    return this.transport.get(`${API._PRODUCT_MANAGER_URL}/authorize`);
  }

  static login(email: string, pwd: string): Promise<LoginInfo> {
    return this.transport
      .post(`${API._PRODUCT_MANAGER_URL}/login`, `email=${encodeURIComponent(email)}&pwd=${encodeURIComponent(pwd)}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((result) => result.data);
  }

  static createUser(User: User): Promise<CreateUserResponse> {
    return this.transport.post(`${API._PRODUCT_MANAGER_URL}/user`, User).then((result) => result.data);
  }

  static setUserPassword(data: User): Promise<void> {
    return this.transport.post(`${API._PRODUCT_MANAGER_URL}/set-user-password`, data).then((result) => result.data);
  }
}

export default API;
