


















import { setLocale } from '@/services/locale';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class LanguagesList extends Vue {
  public languages = [
    { code: 'en', label: 'English' },
    { code: 'es', label: 'Español' },
    { code: 'pt-BR', label: 'Português (Brasil)' }
  ];

  public updateLanguage(code: string) {
    this.$root.$i18n.locale = code;
    setLocale(code);
  }
}
