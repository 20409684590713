


















































































































































































import { Vue, Component } from 'vue-property-decorator';
import API from '@/services/api';
import LanguagesList from '@/components/LanguagesList.vue';
import { message } from 'ant-design-vue';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import * as Sentry from '@sentry/vue';
import { StatusCodes } from 'http-status-codes';
import { DateFormat } from '../enum/date';
import { FormValues } from '@/interfaces/form';

@Component({
  components: {
    LanguagesList
  }
})
export default class Signup extends Vue {
  dateFormat = DateFormat;
  form: WrappedFormUtils;
  confirmDirty = false;
  registered = false;
  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: 'signup' });
  }

  handleSubmit(ev: Event): void {
    ev.preventDefault();

    this.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.signup(values);
      }
    });
  }

  signup(formValues: FormValues): void {
    API.createUser({
      Email: formValues.email,
      Password: formValues.password,
      SignUpOrg: formValues.organization,
      FirstName: formValues.firstName,
      LastName: formValues.lastName,
      Language: formValues.language,
      MeasurementSystem: formValues.measurementSystem,
      DateFormat: formValues.dateFormat
    })
      .then(() => {
        Sentry.setUser({ email: formValues.email, username: formValues.firstName + ' ' + formValues.lastName });
        this.registered = true;
      })
      .catch((err) => {
        if (err?.response?.status === StatusCodes.CONFLICT) {
          message.error(this.$root.$t('errConflict').toString());
          return;
        }
        if (err?.response?.status === StatusCodes.BAD_REQUEST) {
          message.error(this.$root.$t('errSignup').toString());
          return;
        }
        message.error(this.$root.$t('errRetry').toString());
      });
  }

  handleConfirmBlur(e): void {
    const value = e.target.value;
    this.confirmDirty = this.confirmDirty || !!value;
  }

  compareToFirstPassword(rule, value: string, callback: (message?: string) => void): void {
    const form = this.form;
    if (value && value !== form.getFieldValue('password')) {
      callback(this.$root.$t('confirmPasswordInconsistent').toString());
    } else {
      callback();
    }
  }

  validateToNextPassword(rule, value: string, callback: (message?: string) => void): void {
    const form = this.form;
    if (value && this.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }

  get isMahindraDomain(): boolean {
    return window.location.host === process.env.VUE_APP_MAHINDRA_AUTH_HOST;
  }
  returnToLogin() {
    this.$router.push({ name: 'Login' });
  }
}
