
























































































import { Vue, Component, Watch } from 'vue-property-decorator';
import LanguagesList from '@/components/LanguagesList.vue';
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import { FormValues } from '@/interfaces/form';
import API from '@/services/api';
import { User } from '@/interfaces/user';
import { message } from 'ant-design-vue';

@Component({
  components: {
    LanguagesList
  }
})
export default class Password extends Vue {
  form: WrappedFormUtils;
  confirmDirty = false;
  isPasswordSubmitted = false;
  isSaveDisabled = true;

  public passwordForm = {
    new: '',
    confirm: ''
  };

  @Watch('passwordForm', { deep: true })
  public onPasswordFormChange(): void {
    const errors = this.form.getFieldsError() as any;

    this.isSaveDisabled =
      errors.password !== undefined ||
      errors.confirm !== undefined ||
      this.passwordForm.new !== this.passwordForm.confirm ||
      this.passwordForm.new === '' ||
      this.passwordForm.confirm === '';
  }

  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: 'password' });
  }

  public handleSubmit(ev: Event): void {
    ev.preventDefault();

    this.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.submitPassword(values);
      }
    });
  }

  private async submitPassword(values: FormValues): Promise<void> {
    const data: User = {
      id: this.$route.query.id as string,
      Password: values.password,
      PasswordToken: this.$route.query.token as string
    };

    try {
      await API.setUserPassword(data);
      this.isPasswordSubmitted = true;
    } catch (error) {
      message.error(this.$root.$t('error').toString());
    }
  }

  public handleConfirmBlur(e): void {
    const value = e.target.value;
    this.confirmDirty = this.confirmDirty || !!value;
  }

  public compareToFirstPassword(rule, value: string, callback: (message?: string) => void): void {
    const form = this.form;
    if (value && value !== form.getFieldValue('password')) {
      callback(this.$root.$t('passwordDoNotMatch').toString());
    } else {
      callback();
    }
  }

  public validateToNextPassword(rule, value: string, callback: (message?: string) => void): void {
    const form = this.form;
    if (value && this.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }

  public get regexExp(): RegExp {
    return /^(?=.*[A-Z])(?=.*\d).{8,}$/;
  }

  public get userName(): string {
    return this.$route.query.name as string;
  }

  public goToLoginPage(): void {
    this.$router.push({ path: '/' });
  }
}
