var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('a-card',{staticClass:"password-container"},[_c('div',{staticClass:"logo logo-gamaya"},[_c('img',{attrs:{"src":require("../assets/logo.png")}})]),(_vm.isPasswordSubmitted)?_c('div',[_c('div',{staticClass:"text-container"},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"clip-path":"url(#clip0_8327_11316)"}},[_c('path',{attrs:{"d":"M9.00002 16.1996L4.80002 11.9996L3.40002 13.3996L9.00002 18.9996L21 6.99961L19.6 5.59961L9.00002 16.1996Z","fill":"#309257"}})]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_8327_11316"}},[_c('rect',{attrs:{"width":"24","height":"24","fill":"white"}})])])]),_c('h2',[_vm._v(_vm._s(_vm.$t('yourPasswordIsSaved')))]),_c('a-button',{staticClass:"login-goto-btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.goToLoginPage()}}},[_vm._v(_vm._s(_vm.$t('login')))])],1)]):_c('div',{staticClass:"form-container"},[_c('div',{staticClass:"text-container"},[_c('h2',[_vm._v(_vm._s(_vm.$t('welcome', { user: _vm.userName })))]),_c('span',[_vm._v(_vm._s(_vm.$t('setupYourPassword')))])]),_c('a-form',{attrs:{"form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":_vm.$t('password'),"has-feedback":""}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.$t('passwordPlaceholder')
                  },
                  { pattern: _vm.regexExp, message: _vm.$t('passwordPattern') },
                  {
                    validator: _vm.validateToNextPassword
                  }
                ]
              }
            ]),expression:"[\n              'password',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: $t('passwordPlaceholder')\n                  },\n                  { pattern: regexExp, message: $t('passwordPattern') },\n                  {\n                    validator: validateToNextPassword\n                  }\n                ]\n              }\n            ]"}],attrs:{"type":"password"},model:{value:(_vm.passwordForm.new),callback:function ($$v) {_vm.$set(_vm.passwordForm, "new", $$v)},expression:"passwordForm.new"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('confirmPassword'),"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'confirm',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.$t('confirmPasswordPlaceholder')
                  },
                  {
                    validator: _vm.compareToFirstPassword
                  }
                ]
              }
            ]),expression:"[\n              'confirm',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: $t('confirmPasswordPlaceholder')\n                  },\n                  {\n                    validator: compareToFirstPassword\n                  }\n                ]\n              }\n            ]"}],attrs:{"type":"password"},on:{"blur":_vm.handleConfirmBlur},model:{value:(_vm.passwordForm.confirm),callback:function ($$v) {_vm.$set(_vm.passwordForm, "confirm", $$v)},expression:"passwordForm.confirm"}})],1),_c('a-form-item',[_c('a-button',{staticClass:"password-submit-btn",attrs:{"type":"primary","html-type":"submit","disabled":_vm.isSaveDisabled}},[_vm._v(_vm._s(_vm.$t('savePassword')))])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }