var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('a-card',{staticClass:"signup-container"},[(_vm.isMahindraDomain)?_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/mahindra_logo.svg")}})]):_vm._e(),(!_vm.isMahindraDomain)?_c('div',{staticClass:"logo logo-gamaya"},[_c('img',{attrs:{"src":require("../assets/logo.png")}})]):_vm._e(),_c('div',{staticClass:"text-container"},[_c('h3',[_vm._v(_vm._s(_vm.$t('signupMsg')))]),_c('div',{staticClass:"description"},[_c('span',[_vm._v(_vm._s(_vm.$t('signupDescription')))])]),_c('div',{staticClass:"sub-description"},[_c('span',[_vm._v(_vm._s(_vm.$t('haveAccount')))]),_c('router-link',{attrs:{"to":{ name: 'Login' }}},[_vm._v(_vm._s(_vm.$t('login')))])],1)]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":_vm.$t('email')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'email',
            {
              rules: [
                {
                  type: 'email',
                  message: _vm.$t('invalidEmailMessage')
                },
                {
                  required: true,
                  message: _vm.$t('emailPlaceholder')
                }
              ]
            }
          ]),expression:"[\n            'email',\n            {\n              rules: [\n                {\n                  type: 'email',\n                  message: $t('invalidEmailMessage')\n                },\n                {\n                  required: true,\n                  message: $t('emailPlaceholder')\n                }\n              ]\n            }\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('password'),"has-feedback":""}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('passwordPlaceholder')
                },
                { min: 6, max: 30, message: _vm.$t('passwordLengthMessage') },
                {
                  validator: _vm.validateToNextPassword
                }
              ]
            }
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('passwordPlaceholder')\n                },\n                { min: 6, max: 30, message: $t('passwordLengthMessage') },\n                {\n                  validator: validateToNextPassword\n                }\n              ]\n            }\n          ]"}],attrs:{"type":"password"}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('confirmPassword'),"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'confirm',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('confirmPasswordPlaceholder')
                },
                {
                  validator: _vm.compareToFirstPassword
                }
              ]
            }
          ]),expression:"[\n            'confirm',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('confirmPasswordPlaceholder')\n                },\n                {\n                  validator: compareToFirstPassword\n                }\n              ]\n            }\n          ]"}],attrs:{"type":"password"},on:{"blur":_vm.handleConfirmBlur}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('organization')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'organization',
            {
              rules: [
                {
                  required: true,
                  message: _vm.$t('organizationPlaceholder')
                }
              ]
            }
          ]),expression:"[\n            'organization',\n            {\n              rules: [\n                {\n                  required: true,\n                  message: $t('organizationPlaceholder')\n                }\n              ]\n            }\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('firstName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'firstName',
            {
              rules: []
            }
          ]),expression:"[\n            'firstName',\n            {\n              rules: []\n            }\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('lastName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'lastName',
            {
              rules: []
            }
          ]),expression:"[\n            'lastName',\n            {\n              rules: []\n            }\n          ]"}]})],1),_c('a-form-item',{attrs:{"label":_vm.$t('language')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'language',
            {
              rules: []
            }
          ]),expression:"[\n            'language',\n            {\n              rules: []\n            }\n          ]"}]},[_c('a-select-option',{attrs:{"value":"en"}},[_vm._v("English")]),_c('a-select-option',{attrs:{"value":"pt-BR"}},[_vm._v("Portuguese (Brazil) - português (Brasil)")]),_c('a-select-option',{attrs:{"value":"es"}},[_vm._v("Español")])],1)],1),_c('a-form-item',{attrs:{"label":_vm.$t('measurementSystem')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'measurementSystem',
            {
              rules: []
            }
          ]),expression:"[\n            'measurementSystem',\n            {\n              rules: []\n            }\n          ]"}]},[_c('a-select-option',{attrs:{"value":"metric"}},[_vm._v(" "+_vm._s(_vm.$t('metric')))]),_c('a-select-option',{attrs:{"value":"imperial"}},[_vm._v(_vm._s(_vm.$t('imperial')))])],1)],1),_c('a-form-item',{attrs:{"label":_vm.$t('dateFormat')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'dateFormat',
            {
              rules: []
            }
          ]),expression:"[\n            'dateFormat',\n            {\n              rules: []\n            }\n          ]"}]},[_c('a-select-option',{attrs:{"value":_vm.dateFormat.DD_MM_YYYY}},[_vm._v(" "+_vm._s(_vm.dateFormat.DD_MM_YYYY))]),_c('a-select-option',{attrs:{"value":_vm.dateFormat.MM_DD_YYYY}},[_vm._v(_vm._s(_vm.dateFormat.MM_DD_YYYY))])],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"signup-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(_vm._s(_vm.$t('btnSignup')))])],1)],1),_c('languages-list')],1),(_vm.registered)?_c('a-card',{staticClass:"popup"},[_c('a-card',{staticClass:"registered-container"},[_c('div',{staticClass:"row"},[(_vm.isMahindraDomain)?_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/mahindra_logo.svg")}})]):_vm._e(),(!_vm.isMahindraDomain)?_c('div',{staticClass:"logo logo-gamaya"},[_c('img',{attrs:{"src":require("../assets/logo.png")}})]):_vm._e(),_c('div',{staticClass:"heading"},[_vm._v(_vm._s(_vm.$t('registeredHeadingSuccess')))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.$t('registeredMsgSuccess')))]),_c('a-button',{staticClass:"back-to-login-btn",attrs:{"type":"primary"},on:{"click":_vm.returnToLogin}},[_vm._v(_vm._s(_vm.$t('goToViewer')))])],1)])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }