import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showGlobalLoader: false
  },
  mutations: {
    showGlobalLoader(state, payload) {
      state.showGlobalLoader = payload;
    }
  },
  actions: {
    showGlobalLoader({ commit }, isShow) {
      commit('showGlobalLoader', isShow);
    }
  },
  modules: {}
});
