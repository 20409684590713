


































import { Vue, Component } from 'vue-property-decorator';
import API from '@/services/api';
import LanguagesList from '@/components/LanguagesList.vue';
import { message } from 'ant-design-vue';
import * as Sentry from '@sentry/vue';
import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';

@Component({
  components: {
    LanguagesList
  }
})
export default class Login extends Vue {
  authenticating = true;
  email = '';
  password = '';
  mounted() {
    if (this.isMahindraDomain) {
      document.title = document.title.replace('Gamaya', 'Krish-e');
      const faviconLink = document.getElementById('favicon') as HTMLLinkElement;
      faviconLink.href = '/krish-e.png';
    }

    API.authorize()
      .then(() => {
        this.redirect();
      })
      .catch(() => {
        this.authenticating = false;
      });
  }
  redirect(): void {
    const redir = new URL(window.location.href).searchParams.get('redir');
    if (!redir || redir.length === 0) {
      window.location.href = this.isMahindraDomain ? process.env.VUE_APP_MAHINDRA_APP_URL : process.env.VUE_APP_ORB2;
    } else {
      window.location.href = redir;
    }
  }
  login(ev): void {
    ev.preventDefault();
    API.login(this.email, this.password)
      .then(() => {
        this.redirect();
        Sentry.setUser({ email: this.email });
      })
      .catch((e: unknown) => {
        const res = (e as AxiosError).response;

        if (res.status === StatusCodes.UNAUTHORIZED) {
          message.error(this.$root.$t('errLogin').toString());
          return;
        }

        if (res.status === StatusCodes.FORBIDDEN) {
          message.error(this.$root.$t('errPendingReg').toString());
          return;
        }

        message.error(this.$root.$t('errRetry').toString());
      });
  }

  get isMahindraDomain(): boolean {
    return window.location.host === process.env.VUE_APP_MAHINDRA_AUTH_HOST;
  }

  get loginDescription(): string {
    if (this.isMahindraDomain) {
      return this.$root.$t('loginDescriptionMahindra').toString();
    }
    return this.$root.$t('loginDescription').toString();
  }
}
